<template>
  <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col
              lg="6"
              class="bg-primary d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="9">
                      <div>
                        <h2 class="display-5 text-white fw-medium">
                          Receba à vista pelo que você vendeu a prazo!
                        </h2>
                        <p class="mt-4 text-white op-5 font-weight-normal">
                          A antecipação de recebíveis é uma escolha inteligente e eficaz
                          porque você usa um dinheiro que já pertence à sua empresa
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col lg="6" class="d-md-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="6">
                      <img src="@/assets/images/logo.png" width="300" />
                      <h2 class="font-weight-bold mt-4">
                        {{ $t("GERAL.REDEFINIR_SENHA") }}
                      </h2>
                      <div>
                        <input-text
                          ref="senha"
                          v-model="form.senha"
                          type="password"
                          :label="$t('GERAL.SENHA')"
                          :placeholder="$t('GERAL.SENHA')"
                          required
                          class="mb-2"
                        />
                        <verifica-forca-senha
                          embaixo
                          :value="forcaSenha"
                          class="mb-2"
                        ></verifica-forca-senha>
                      </div>

                      <input-text
                        ref="novaSenha"
                        v-model="form.novaSenha"
                        type="password"
                        :label="$t('GERAL.CONFIRMACAO_SENHA')"
                        :placeholder="$t('GERAL.CONFIRMACAO_SENHA')"
                        :validate="validarSenha"
                        required
                      />

                      <b-button
                        variant="primary"
                        size="lg"
                        block
                        class="mt-4"
                        @click="resetarSenha"
                        >{{ $t("GERAL.TROCAR_SENHA") }}</b-button
                      >

                      <div class="ml-auto">
                        <a @click="voltarLogin" href="javascript:void(0)" class="link">{{
                          $t("GERAL.VOLTAR_LOGIN")
                        }}</a>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import InputText from "@/components/inputs/InputText";
import UsuarioService from "@/common/services/usuario/usuario.service";
import VerificaForcaSenha from "../usuarios/components/VerificaForcaSenha.vue";
import Swal from "sweetalert2";
export default {
  name: "Login",
  components: {
    InputText,
    VerificaForcaSenha,
  },
  data() {
    return {
      form: {
        usuarioId: this.$route.query.usuarioId,
        token: null,
        senha: null,
        novaSenha: null,
      },
      forcaSenha: null,
    };
  },
  computed: {
    validarSenha() {
      if (!this.comparaSenha()) return false;
      return true;
    },
  },
  mounted() {
    UsuarioService.obterToken(this.$route.query.token)
      .then((result) => {
        this.form.token = result.data.data.accessToken;
      })
      .catch(() => {})
      .finally(() => {
        this.$store.dispatch(STOP_LOADING);
      });
  },
  watch: {
    "form.senha"() {
      this.verificarForcaDaSenha();
    },
  },
  methods: {
    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha)
        .then((result) => {
          this.forcaSenha = result.data.forcaSenhaEnum;
        })
        .catch((err) => console.log(err));
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.novaSenha.valid());
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    resetarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      let form = this.form;
      UsuarioService.resetarSenha(form)
        .then(() => {
          Swal.fire({
            title: this.$t("GERAL.SUCCESSO"),
            text: this.$t("GERAL.SENHA_ALTERADA"),
            icon: "success",
            confirmButtonText: this.$t("GERAL.OK"),
          });
          this.$router.push({ name: "Login" });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    comparaSenha() {
      if (this.form.senha !== this.form.novaSenha) {
        return false;
      }
      return true;
    },
    voltarLogin() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
